import logo from '../img/caresful.png';

const defaultSize = {
  width: 'auto',
  verticalAlign: 'middle',
};

export const Logo = (props: { width: number; height: number }): JSX.Element => {
  return <img src={logo} width={props.width} height={props.height} style={defaultSize} alt="Caresful" />;
};

export default Logo;
